import { Button } from '@veneer/core';
import React from 'react';
import * as C from '../styles';

const Logout: React.FC = () => {
  const { logout } = window.Shell.v1?.sessionInterface || {};
  const isLoggedIn = window.Shell.v1?.sessionInterface?.isLoggedIn?.();

  return (
    <C.Container>
      <h6>logout()</h6>

      <C.FormContainer>
        <Button
          small
          disabled={!isLoggedIn}
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Button>
      </C.FormContainer>
    </C.Container>
  );
};

export default Logout;

import React from 'react';
import * as S from './styles';
import { MANIFEST_STORAGE_KEY, IMPORTMAP_STORAGE_KEY } from '../../constants';
import { IconDocumentCopy } from '@veneer/core';

type FloatButtonComponentProps = {
  setOpen: (param: boolean) => void;
};

const FloatButtonComponent = ({ setOpen }: FloatButtonComponentProps) => {
  function handleClick() {
    setOpen(true);
  }
  const isOverridden =
    localStorage.getItem(MANIFEST_STORAGE_KEY) ||
    localStorage.getItem(IMPORTMAP_STORAGE_KEY);
  return (
    <S.Container>
      {isOverridden ? (
        <S.FloatButton
          leadingIcon={<IconDocumentCopy />}
          onClick={() => handleClick()}
        >
          Tools
        </S.FloatButton>
      ) : (
        <S.FloatButton onClick={() => handleClick()}>Tools</S.FloatButton>
      )}
    </S.Container>
  );
};

export default FloatButtonComponent;

export const LOCALSTORAGE_OD_URLS = 'onboarding-director-urls';

export type LocalStorageEndpointUrlsType = {
  sessionResourceUrl: {
    local?: string;
    default?: string;
  };
  applicationContextUrl: {
    local?: string;
    default?: string;
  };
};

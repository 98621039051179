import React from 'react';
import * as S from './styles';
import ManifestOverrideComponent from '../ManifestOverrideComponent';
import ImportmapOverrideComponent from '../ImportmapOverrideComponent';
import { MANIFEST_STORAGE_KEY, IMPORTMAP_STORAGE_KEY } from '../../constants';
import { SelectItem } from './types';

const OverrideComponent = () => {
  const [manifest, setManifest] = React.useState<string>(
    localStorage.getItem(MANIFEST_STORAGE_KEY)
  );
  const [importmap, setImportmap] = React.useState<string>(
    localStorage.getItem(IMPORTMAP_STORAGE_KEY)
  );
  const [unsavedImportmap, setUnsavedImportmap] = React.useState<string>('');
  const [unsavedManifest, setUnsavedManifest] = React.useState<string>('');

  const items: SelectItem[] = [
    {
      value: 1,
      label: 'Importmap',
      content: ImportmapOverrideComponent
    },
    {
      value: 2,
      label: 'Manifest',
      content: ManifestOverrideComponent
    }
  ];

  const [selectedItem, setSelectedItem] = React.useState<SelectItem>(items[0]);

  const onChange = (selectedOption: SelectItem) => {
    setSelectedItem(() => selectedOption);
  };

  return (
    <>
      <S.SelectOverride
        id="jshell-tools-override-select"
        options={items}
        label="Select"
        placement="bottom"
        helperTextVisibility="auto"
        onChange={onChange}
        clearIcon={false}
        value={[selectedItem.value]}
      />
      <selectedItem.content
        {...{
          importmap,
          manifest,
          unsavedImportmap,
          unsavedManifest,
          setImportmap,
          setManifest,
          setUnsavedImportmap,
          setUnsavedManifest
        }}
      />
    </>
  );
};

export default OverrideComponent;

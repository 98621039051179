import React, { useState } from 'react';
import { Button, TextArea } from '@veneer/core';
import * as C from '../styles';

const GetScopes: React.FC = () => {
  const [scopeList, setScopeList] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = window.Shell.v1?.sessionInterface?.isLoggedIn?.();

  return (
    <C.Container>
      <strong>
        <h6>getScopes()</h6>
      </strong>
      <C.FormContainer>
        <Button
          small
          loading={isLoading}
          disabled={!isLoggedIn}
          onClick={async () => {
            try {
              setIsLoading(true);
              const tenantId =
                await window.Shell.v1?.tenantHandlerInterface?.getTenantId();
              const scopeStorageKey = `jshellUserScopes-${tenantId}`;
              const storagedScopes = localStorage.getItem(scopeStorageKey);
              setScopeList(
                storagedScopes
                  ? JSON.stringify(JSON.parse(storagedScopes), null, 2)
                  : `There's no scope to show`
              );
            } catch (error) {
              alert(error);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Get Scopes
        </Button>
      </C.FormContainer>
      <div>
        {scopeList ? (
          <TextArea
            data-testid="scopesText"
            readOnly
            value={scopeList}
          />
        ) : (
          <></>
        )}
      </div>
    </C.Container>
  );
};

export default GetScopes;

import * as S from './styles';
import React from 'react';
import { Tabs, ToastProvider } from '@veneer/core';
import OverrideComponent from '../OverrideComponent';
import ActionsComponent from '../ActionsComponent';
import ProfilingMetricsComponent from '../ProfilingMetricsComponent';

const ModalComponent = ({ openModal, setOpenModal }) => {
  const tabs = [
    {
      id: 1,
      label: 'Overrides',
      content: <OverrideComponent />
    },
    {
      id: 2,
      label: 'Actions',
      content: <ActionsComponent />
    }
  ];

  if (window.profilingRecords?.length > 0) {
    tabs.push({
      id: 3,
      label: 'Profiling',
      content: <ProfilingMetricsComponent />
    });
  }

  const [selectedTabIdDefault, setSelectedTabIdDefault] = React.useState(
    tabs[0].id
  );

  return (
    <ToastProvider position="top">
      <S.ModalTool
        expanded={true}
        align="start"
        show={openModal}
        onClose={() => setOpenModal(false)}
        title="ClientOS Developer Tools"
      >
        <Tabs
          controlId="default"
          mode="extended"
          onChangeTab={(id) => setSelectedTabIdDefault(id)}
          selectedTabId={selectedTabIdDefault}
          tabs={tabs}
          type="online"
        />
      </S.ModalTool>
    </ToastProvider>
  );
};

export default ModalComponent;

import React from 'react';
import FloatButtonComponent from '../components/FloatButtonComponent';
import ModalComponent from '../components/ModalComponent';
import { Container } from './styles';
import { render } from 'react-dom';

const App = () => {
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <Container>
      <FloatButtonComponent setOpen={setOpenModal} />
      <ModalComponent
        setOpenModal={setOpenModal}
        openModal={openModal}
      />
    </Container>
  );
};

const shellToolsId = '#shell-tools';
const shellToolsDiv = document.createElement('div');
shellToolsDiv.setAttribute('id', shellToolsId);
document.querySelector('body').appendChild(shellToolsDiv);
render(<App />, shellToolsDiv);

export default App;

import React from 'react';
import * as A from '../ActionsComponents';

const ActionsComponent: React.FC = () => {
  return (
    <>
      <div>
        <A.GetTenantId />
        <A.GetToken />
        <A.GetScopes />
        <A.OnboardingNextStep />
        <A.ChangeODApplicationContextUrl />
        <A.ChangeODSessionResourceUrl />
        <A.Logout />
      </div>

      <a
        href="https://pages.github.azc.ext.hp.com/jarvis-shell/shell-docs/"
        target="_blank"
        rel="noreferrer"
      >
        Click here for documentation.
      </a>
    </>
  );
};
export default ActionsComponent;

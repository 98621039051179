import { Button } from '@veneer/core';
import React from 'react';
import * as C from '../styles';

const OnboardingNextStep: React.FC = () => {
  return (
    <C.Container>
      <h6>onboardingNextStep()</h6>

      <C.FormContainer>
        <Button
          appearance="primary"
          small
          onClick={() => {
            window.Shell.v1.serviceRouting.closeServiceInstance({
              resultData: { result: 'success' }
            });
          }}
        >
          Force Onboarding Next Step
        </Button>
      </C.FormContainer>
    </C.Container>
  );
};

export default OnboardingNextStep;

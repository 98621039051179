import styled from 'styled-components';

export const ButtonContainer = styled.div`
  min-width: 112px;
`;

export const Container = styled.div`
  padding: 10px 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 4px;
`;

export const TextContainer = styled.div`
  padding: 5px 10px;
`;

import styled from 'styled-components';

export const ODComponentContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 4px;
`;

export const ODTextBoxContainer = styled.div`
  max-width: 500px;
`;

export const ODActionButtonsContainers = styled.div`
  display: flex;
  gap: 10px;
`;

import styled from 'styled-components';
import { Button } from '@veneer/core';

export const Container = styled.div`
  position: fixed;
  z-index: 1000;
  width: fit-content;
  height: fit-content;
  bottom: 40px;
  right: 40px;
  text-align: center;
`;

export const FloatButton = styled(Button)``;

import React, { useState } from 'react';
import * as C from '../styles';
import { TextArea, Button } from '@veneer/core';

const GetToken: React.FC = () => {
  const { getAccessToken } = window.Shell.v1?.authProvider || {};
  const [accessToken, setAccessToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = window.Shell.v1?.sessionInterface?.isLoggedIn?.();

  return (
    <C.Container>
      <h6>getAccessToken()</h6>
      <C.FormContainer>
        <Button
          small
          loading={isLoading}
          disabled={!isLoggedIn}
          onClick={async () => {
            try {
              setIsLoading(true);
              const response = await getAccessToken(true);
              setAccessToken(response);
            } catch (error) {
              alert(error);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Get Access Token
        </Button>
      </C.FormContainer>

      {accessToken ? (
        <TextArea
          value={accessToken}
          data-testid="tokenTest"
        />
      ) : (
        <></>
      )}
    </C.Container>
  );
};

export default GetToken;

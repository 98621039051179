import React, { useState } from 'react';
import { Button } from '@veneer/core';
import { AuthContextEnum } from '@jarvis/shell-commons/dist/services/authTokenService';
import * as C from '../styles';

const GetTenantId: React.FC = () => {
  const { getTenantId } = window.Shell.v1.tenantHandlerInterface || {};
  const [tenantId, setTenantId] = useState<string>('');
  const isLoggedIn = window.Shell.v1?.sessionInterface?.isLoggedIn?.();

  return (
    <C.Container>
      <strong>
        <h6>getTenantId()</h6>
      </strong>
      <C.FormContainer>
        <Button
          small
          disabled={!isLoggedIn}
          onClick={() => {
            setTenantId(getTenantId('tenant' as AuthContextEnum));
          }}
        >
          Get Tenant ID
        </Button>
      </C.FormContainer>
      <div>
        {tenantId ? (
          <p data-testid="tenant-result">Tenant ID: {tenantId}</p>
        ) : (
          <></>
        )}
      </div>
    </C.Container>
  );
};

export default GetTenantId;

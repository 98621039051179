import styled from 'styled-components';

export const AssetReference = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1rem;
  grid-template-areas:
    'project caret'
    'mfe caret';
  flex-direction: column;
  line-height: 1.2;
  text-wrap: nowrap;

  > .project {
    grid-area: project;
    font-size: 0.8em;
    opacity: 0.7;
  }

  > .mfe {
    grid-area: mfe;
  }

  &:not(:last-child)::after {
    grid-area: caret;
    content: '›';
    display: inline-block;
    font-size: 1.3em;
    width: 1.25rem;
    text-align: center;
    line-height: 1;
    align-self: center;
  }
`;

export const TreeList = styled.ul`
  list-style-type: none;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  align-items: center;
  gap: 0.25rem;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 0.5rem;
`;

import { Button, TextBox } from '@veneer/core';
import React, { useState } from 'react';
import { Container } from '../../styles';
import * as S from '../styles';
import { LocalStorageEndpointUrlsType, LOCALSTORAGE_OD_URLS } from '../types';

const ChangeODSessionResourceUrl: React.FC = () => {
  const [odUrlsObject] = useState<LocalStorageEndpointUrlsType>(
    () => JSON.parse(window.localStorage.getItem(LOCALSTORAGE_OD_URLS)) || {}
  );

  const [sessionResourceUrl, setSessionResourceUrl] = useState<string>(
    odUrlsObject?.sessionResourceUrl?.local ||
      odUrlsObject?.sessionResourceUrl?.default ||
      ''
  );

  const setLocalStorageItemOnButtonClickAndReload = (value: string) => {
    window.localStorage.setItem(
      LOCALSTORAGE_OD_URLS,
      JSON.stringify({
        ...odUrlsObject,
        sessionResourceUrl: {
          ...odUrlsObject.sessionResourceUrl,
          local: value
        }
      })
    );
    window.location.reload();
  };

  return (
    <Container>
      <h6>changeODSessionResourceUrl()</h6>

      <S.ODComponentContainer>
        <S.ODTextBoxContainer>
          <TextBox
            value={sessionResourceUrl}
            onChange={(value) => setSessionResourceUrl(value)}
          />
        </S.ODTextBoxContainer>
        <S.ODActionButtonsContainers>
          <Button
            appearance="primary"
            small
            onClick={() =>
              setLocalStorageItemOnButtonClickAndReload(sessionResourceUrl)
            }
          >
            Change
          </Button>
          <Button
            appearance="primary"
            small
            onClick={() => setLocalStorageItemOnButtonClickAndReload(undefined)}
          >
            Reset
          </Button>
        </S.ODActionButtonsContainers>
      </S.ODComponentContainer>
    </Container>
  );
};

export default ChangeODSessionResourceUrl;

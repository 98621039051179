import React from 'react';
import AceEditor, { IAnnotation } from 'react-ace';
import * as S from './styles';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/ext-error_marker';

const JSONEditorComponent = ({
  value,
  onChange,
  annotations,
  name,
  isNotSaved
}: {
  value: string;
  onChange: (value: string) => void;
  annotations?: IAnnotation[];
  name: string;
  isNotSaved?: boolean;
}) => {
  return (
    <S.Wrapper
      isNotSaved={isNotSaved}
      isError={!!annotations?.length}
      data-testid="jshell-tools-json-editor-component"
    >
      <AceEditor
        placeholder={process.env.NODE_ENV === 'test' ? 'Enter your text' : ''}
        width="100%"
        mode="json"
        annotations={annotations}
        onChange={onChange}
        name={name}
        editorProps={{ $blockScrolling: true }}
        value={value}
        showGutter={true}
        highlightActiveLine={true}
        wrapEnabled={true}
        setOptions={{
          useWorker: false
        }}
      />
    </S.Wrapper>
  );
};

export default JSONEditorComponent;

import { Button, TextBox } from '@veneer/core';
import React, { useState } from 'react';
import { Container } from '../../styles';
import * as S from '../styles';
import { LocalStorageEndpointUrlsType, LOCALSTORAGE_OD_URLS } from '../types';

const ChangeODApplicationContextUrl: React.FC = () => {
  const [odUrlsObject] = React.useState<LocalStorageEndpointUrlsType>(
    () => JSON.parse(window.localStorage.getItem(LOCALSTORAGE_OD_URLS)) || {}
  );

  const [applicationContextUrl, setApplicationContextUrl] = useState<string>(
    odUrlsObject?.applicationContextUrl?.local ||
      odUrlsObject?.applicationContextUrl?.default ||
      ''
  );

  const setLocalStorageItemOnButtonClickAndReload = (value: string) => {
    window.localStorage.setItem(
      LOCALSTORAGE_OD_URLS,
      JSON.stringify({
        ...odUrlsObject,
        applicationContextUrl: {
          ...odUrlsObject.applicationContextUrl,
          local: value
        }
      })
    );
    window.location.reload();
  };

  return (
    <Container>
      <h6>changeODApplicationContextUrl()</h6>

      <S.ODComponentContainer>
        <S.ODTextBoxContainer>
          <TextBox
            value={applicationContextUrl}
            onChange={(value) => setApplicationContextUrl(value)}
          />
        </S.ODTextBoxContainer>
        <S.ODActionButtonsContainers>
          <Button
            appearance="primary"
            small
            onClick={() =>
              setLocalStorageItemOnButtonClickAndReload(applicationContextUrl)
            }
          >
            Change
          </Button>
          <Button
            appearance="primary"
            small
            onClick={() => setLocalStorageItemOnButtonClickAndReload(undefined)}
          >
            Reset
          </Button>
        </S.ODActionButtonsContainers>
      </S.ODComponentContainer>
    </Container>
  );
};

export default ChangeODApplicationContextUrl;

import { DirectionProvider } from '@veneer/core';
import ThemeProvider from '@veneer/theme/dist/theme_provider';
import React, { useEffect, useState } from 'react';
import { App } from '../src';
import resources from '../src/assets/locale';
import projectNames from '../src/configs/projectNames';
import { ShellRootProvider } from '../src/contexts/ShellRoot';
import {
  InterfacesType,
  ShellRootComponentProps,
  TranslatorFunctionType
} from '../src/types/shell';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Shell: InterfacesType & any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clientOSWebMFE: { importmap?: any; manifest?: any } & any;
  }
}

export default function Root({
  useToast,
  properties
}: ShellRootComponentProps) {
  const [t, setT] = useState<TranslatorFunctionType>();
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const { v1, v2 } = window.Shell;
  const interfaces = {
    v1,
    v2
  };

  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.();
  const directionValue =
    v1?.localization?.useReactGetLanguageDirection?.(React);

  // Create a translator function by providing the resource files
  useEffect(() => {
    v1.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v));
  }, [v1]);

  // Wait for the translator function creation to show the content
  if (!t) {
    return null;
  }

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <DirectionProvider direction={directionValue}>
        <ThemeProvider
          {...themeProviderProps}
          mode={v1?.theme?.getUserThemeMode?.()}
        >
          <ShellRootProvider {...{ interfaces, t, useToast, properties }}>
            <App />
          </ShellRootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  );
}
